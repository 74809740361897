import React from 'react'

const PrivacyPolicy = () => {
    window.scrollTo(0, 0);

  return (
    <>
  <div className="container" style={{marginTop:"7rem"}}>
  <div class="container mt-5">
        <h1 class="mb-4">Privacy Policy</h1>

        <section class="mb-4">
            <h2>How Do We Collect Your Information?</h2>
            <p>Depending on where you live, how you interact with our Sites and/or Products, the nature of your Communications, and the types of Third Parties with which we may engage, we may collect the following pieces of information from and about you:</p>
            <ul>
                <li>Company name and position;</li>
                <li>Signature;</li>
                <li>Mailing address;</li>
                <li>Shipping address;</li>
                <li>City, state, and ZIP code;</li>
                <li>E-mail address;</li>
                <li>Employer and job title;</li>
                <li>Telephone number;</li>
                <li>Birthday;</li>
                <li>Information you provide to us in response to surveys and contests;</li>
                <li>Information you provide to us on forms available on our Sites;</li>
                <li>Certain information about your physical and medical condition;</li>
                <li>Responses generated in connection with participation in online research;</li>
                <li>Photographs of you;</li>
                <li>History of your interactivity with our Sites and Products;</li>
                <li>Reports of issues with our Sites and Products;</li>
                <li>Correspondence (including email);</li>
                <li>Submissions and voting and polling activities;</li>
                <li>Usage and activity information relating to the Sites and Products;</li>
                <li>Search queries on our Sites, including through certain forms;</li>
                <li>Information about your interests; and/or</li>
                <li>Technical information gathered automatically when you use the Sites and/or Products, including:
                    <ul>
                        <li>Device information.</li>
                        <li>Browser information.</li>
                        <li>Internet service provider or mobile carrier.</li>
                        <li>Referring and exit pages.</li>
                        <li>Date and time stamps.</li>
                        <li>Clickstream data (including page clicks and scrolls).</li>
                        <li>Location (for access and use verification).</li>
                        <li>Bandwidth speed.</li>
                        <li>IP address and device ID.</li>
                        <li>Information from cookies and similar technologies (see below for more information).</li>
                    </ul>
                </li>
            </ul>
            <p>We collect this information in one of three ways:</p>
            <ol>
                <li><strong>Directly from you:</strong> We collect information directly from you when you use our Sites, engage with our Products, or engage in Communications with us.</li>
                <li><strong>Automatically Using Online Technologies:</strong> We collect information about your engagement with our Sites, Products, and Communications when you visit our Sites, open or click on emails we send you or related Communications, or interact with our advertisements. Other third parties may also be collecting your information using online tracking technologies such as cookies, pixels, web beacons, software developer kits, and related technologies. For more information, see Cookies and Similar Online Tracking Technologies.</li>
                <li><strong>From Our Partners:</strong> We collect information from our third-party partners, service providers, clients, data analytics providers, marketing or advertising providers, fraud prevention service providers, and vendors.</li>
            </ol>
        </section>

        <section class="mb-4">
            <h2>How Do We Use Your Information?</h2>
            <p>Depending on where you live, how you interact with our Sites and/or Products, the nature of your Communications, and the types of Third Parties with which we may engage, we may use your information for a variety of purposes, including to:</p>
            <ul>
                <li>Perform a contract with you to:
                    <ul>
                        <li>Provide our Sites and Products to you;</li>
                        <li>Notify you about changes to our Sites and Products;</li>
                        <li>Enforce our terms, conditions, and policies;</li>
                        <li>Administer the Sites and Products; and</li>
                        <li>Personalize content you receive and provide you with tailored content.</li>
                    </ul>
                </li>
                <li>Use internally to:
                    <ul>
                        <li>Perform data analytics (including market research, trend analysis, financial analysis);</li>
                        <li>Operate, evaluate, develop, manage and improve our Sites and Products (including operating, administering, analyzing, and improving our Sites and Products, developing new Sites and Products, managing and evaluating the effectiveness of our Communications, performing accounting, auditing, and billing reconciliation and collection activities and other internal functions);</li>
                        <li>Protect against, identify and prevent fraud and/or other criminal activity, claims and other liabilities; and</li>
                        <li>Comply with and enforce relevant industry standards and policies, including this Privacy Policy and other related terms of use.</li>
                    </ul>
                </li>
                <li>Comply with a legal obligation and/or protect the vital interests of our customers and employees by:
                    <ul>
                        <li>Preventing and responding to abuse and fraud;</li>
                        <li>Preventing and responding to illegal activity;</li>
                        <li>Preventing and responding to other harmful content distributed through our Sites and/or Products;</li>
                        <li>Prevent the unlawful use and/or selling of our Sites and/or Products; and</li>
                        <li>Enforcing our terms, conditions, and policies.</li>
                    </ul>
                </li>
                <li>Perform other business operations, such as a merger, sale, acquisition, proceed with bankruptcy, engage in due diligence relating to the acquisition or sale of part or some of Probill, and related functions.</li>
            </ul>
        </section>

        <section class="mb-4">
            <h2>How Do We Disclose Your Information?</h2>
            <p>We may disclose your information in the following ways:</p>
            <ul>
                <li><strong>With Your Consent:</strong> We may, from time to time, disclose or share your information with your consent. We may obtain your consent in writing, online through “click-through” or related agreements, when you accept terms of service on our Sites and/or through our Products, orally, in person, on the telephone, or by other means. We may share your information in these circumstances to complete a transaction, perform a service, or offer you a Product.</li>
                <li><strong>With Our Subsidiaries and Affiliates:</strong> We may, from time to time, disclose your information to our subsidiaries and affiliates for organizational and internal purposes.</li>
                <li><strong>With Third Parties:</strong> We may share your information with certain Third Parties, such as business partners, advertising networks, marketing partners, data analytics providers, online and offline data collectors, operating systems and platforms, and service providers who provide us a service (e.g., credit / debit card processing, billing, customer service, auditing, marketing, debugging to identify and repair error and/or protecting against malicious, deceptive, fraudulent, or illegal activity).</li>
                <li><strong>Business Transfers / Bankruptcy:</strong> We may transfer your information as part of a corporate business transaction, such as a merger or acquisition, joint venture, corporate reorganization, financing, or sale of company assets, or in the unlikely event of insolvency, bankruptcy, or receivership, in which to any successor or to any person or entity that acquires ownership or control of the Sites and/or Products. This may occur in the event of a merger, acquisition, bankruptcy or other sale of all or a portion of our assets. Other than to the extent ordered by a bankruptcy or other court proceeding, the use and disclosure of all transferred information will be subject to this Privacy Policy, unless otherwise provided.</li>
                <li><strong>By Law or To Protect Rights:</strong> We may share your information to satisfy any law, regulation, legal process, governmental request, or where we have a good belief that access, use, preservation or disclosure of such information is reasonably necessary to: (1) enforce or apply agreements; (2) protect our rights or interests, property or safety or that of others; (3) in connection with claims, disputes, or litigation (in court or elsewhere); or (4) protect users of our Sites.</li>
            </ul>
        </section>

        <section class="mb-4">
            <h2>Do We Transfer Your Information To Other Countries?</h2>
            <p>The information we collect from and/or about you may be stored on servers and with Third Parties in the United States (where we are headquartered) or other countries, and whose laws may differ from the jurisdiction in which you live. Any transfer of your information is done with reasonable consideration of the applicable laws in the jurisdictions in which we operate.</p>
        </section>

        <section class="mb-4">
            <h2>How Do We Store & Protect Your Information?</h2>
            <p>Although no system can guarantee the complete security of your information, we take commercially reasonable steps, appropriate to the nature of information at issue, to ensure your information is subject to appropriate organizational, technical, and physical safeguards in alignment with applicable laws and regulations.</p>
        </section>

        <section class="mb-4">
            <h2>How Long Do We Keep Your Information?</h2>
            <p>We keep your information for as long as necessary in accordance with the purposes for which it was collected, our business needs, and applicable legal and regulatory obligations. If we dispose of your information, we will do so in a way that is secure and appropriate to the nature of the information subject to disposal, consistent with applicable laws and regulations.</p>
        </section>

        <section class="mb-4">
            <h2>Links To Other Sites</h2>
            <p>We may, from time to time, offer links to other websites from our Sites and through our Products. These third parties may have terms of use and privacy policies that are different than ours. We recommend you review such site’s terms of use and privacy policies prior to use or submitting any information to them. We cannot verify, warrant, endorse or take responsibility for the availability, accuracy, completeness, quality, practices, or policies of such sites or content available from such sites. We do not make any representations or warranties as to the security of any information (including, without limitation, credit card and other personal information) you might be requested to give any third party.</p>
        </section>

        <section class="mb-4">
            <h2>Cookies, Advertising, & Tracking</h2>
            <p>From time to time, our Sites may collect and share your information through the use of cookies, pixels, and related technologies for the purpose of identifying and authenticating users, preventing fraud, enhancing our Sites, and to tailor content to you.</p>
            <p>Cookies are small information files that our Sites place on your computer or other devices. Some cookies are temporary. Others are permanent. And there are different types of cookies that serve different purposes, such as functionality and targeting (marketing/advertising).</p>
            <p>Our Sites may also use embedded scripts and/or web beacons. An embedded script is programming code used to collect information about how you interact with our Sites. The embedded script is temporarily downloaded onto your device from our, or a third party web server and is active only while you are connected to the Sites. A web beacon is a small graphic image or programming code that may be included in our Site pages and email messages.</p>
            <p>Our Sites may also use data analytics vendors to monitor the performance of our Sites, enhance and provide a more relevant browsing experience for our users, personalize the content on our Sites, and better understand our users’ needs and optimize our Sites. To help us accomplish these goals, our data analytics vendors may use cookies, identifiers for mobile devices and other technologies to collect and store data about our users’ behavior and their devices, such as how much time users spend on which pages, which links they choose to click, what users do and do not like, etc. This data may include a device’s IP address, device screen size, device type (unique device identifiers), and browser information. For further details about our data analytics vendor, Google Analytics, please see <a href="https://www.google.com/policies/privacy/partners/" target="_blank">How Google uses data when you use our partners’ sites or apps</a> and visit Google’s Privacy Policy at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.</p>
        </section>

        <section class="mb-4">
            <h2>Your Choices</h2>
            <p>We provide you the ability to exercise certain controls and choices regarding your information. To exercise any of the rights described below, send your request to <a href="mailto:hello@Probillrcm.com">hello@Probillrcm.com</a>. We will respond to your request as soon as possible and in accordance with applicable laws and regulations.</p>
            <p>You may submit your request directly, or through an authorized agent, subject to additional verification of your identity and/or the agent’s authorization. To protect your identity, we may also use reasonable efforts, consistent with applicable law, to verify your identity before responding to your request.</p>
            <ul>
                <li><strong>Correct Your Information:</strong> Depending on where you live, you may have a right to request that we correct incorrect or inaccurate information about you. If appropriate, we will use reasonable efforts to allow you to update or correct the information we collect from and about you.</li>
                <li><strong>Access Your Information:</strong> Depending on where you live, you may have a right to know what information we have collected about or from you, how that information is being used, and with whom it is being shared.</li>
                <li><strong>Delete Your Information:</strong> Depending on where you live, and subject to certain exceptions recognized by applicable law, you may have a right to ask that we delete your information.</li>
                <li><strong>Withdraw Consent:</strong> You may withdraw your consent at any time.</li>
                <li><strong>Control Cookies Through Your Browsers:</strong> You may be able to disable and manage some cookies through your browser settings. If you use multiple browsers on the same device, you will need to manage your settings for each browser.</li>
                <li><strong>Analytics Provider Opt-Outs:</strong> To disable analytics cookies you can use the browser controls discussed above or, for some of our providers (e.g., Google), you can use their individual opt-out mechanisms.</li>
                <li><strong>Email Opt-Out:</strong> If at any time you wish to opt out or “unsubscribe” from receiving commercial emails related to our Sites or Products, you may use the unsubscribe link at the bottom of each commercial email you receive or respond to the e-mail with “unsubscribe” written into the body of the reply email. Please note that even if you opt-out of receiving commercial emails from us, we reserve the right to send transactional or relationship communications to you (e.g., if we need to contact you regarding your account status, technical support, changes to our Sites or Services, etc.).</li>
                <li><strong>Non-Discrimination:</strong> We will not discriminate against you for exercising any of your rights under applicable law and this section. That means if you exercise any of your rights in connection with your information, we will not deny you access or use of our Sites or our Products, charge you a different price or rate for any Products, or provide you a different level of service, subject to exceptions under applicable law.</li>
            </ul>
            <p>If you have questions or complaints about how we have handled your information, or responded to any requests above, please contact us below. You also have the right, depending on where you live, to file a complaint with any competent data protection authority, regulator, or governmental agency, where appropriate.</p>
            <p>Probill does not sell or share the personal information of California residents. We also do not have any actual knowledge of selling or sharing the personal information of any California resident who is 16 years or younger.</p>
        </section>

        <section class="mb-4">
            <h2>Notice of Processing Sensitive Personal Information</h2>
            <p>Probill does not process your sensitive personal information outside of the permissible purposes set forth in the CCPA.</p>
        </section>

        <section class="mb-4">
            <h2>Your Rights</h2>
            <p>You have a right to submit a request to know, delete, and correct your personal data. You also have a right to appeal a denial of any of these requests by submitting an appeal to <a href="mailto:hello@probillrcm.com">hello@probillrcm.com</a>.</p>
        </section>

        
        <section class="mb-4">
            <h2>Do Not Track
            </h2>
            <p>Our Sites may, from time to time, collect information about your online activities, over time and across different sites and applications. When you use our Sites, Third Parties may also collect information about your online activities, over time and across different websites, online or cloud computing services, online applications, or mobile applications. Some browsers support a “Do Not Track” feature, which is intended to be a signal to websites that you do not wish to be tracked across different websites you visit. Our Sites currently do not change the way they operate based upon a “Do Not Track” or similar signal. You may, however, disable certain tracking as discussed in Your Choices section.</p>
        </section>
        <section class="mb-4">
            <h2>Children
            </h2>
            <p>Our Sites are not intended for children under the age of 18. We do not knowingly collect or use any information from individuals under the age of 18. If you are a parent or guardian who has discovered that your child under the age of 18 has submitted his or her information without your permission or consent, you can ask us to remove your child’s information by contacting us at  <a href="mailto:hello@probillrcm.com">hello@probillrcm.com</a>.</p>
        </section>
        <section class="mb-4">
            <h2>Changes
            </h2>
            <p>We may change this Privacy Policy from time to time. Any and all changes will be reflected on this page, and where appropriate and consistent with applicable law and regulation, provided to you in person or by another electronic method. The last updated date will be stated at the top of this Privacy Policy. You should regularly check this page for any changes to this Privacy Policy.</p>
        </section>
        <section class="mb-4">
            <h2>Accessibility
            </h2>
            <p>To request a copy of this Privacy Policy in an alternative format, please contact us at the information provided below.</p>
        </section>
        <section class="mb-4">
            <h2>Contact Us
            </h2>
            <p>If you have any questions about this Privacy Policy, please contact us at:<a href="mailto:hello@probillrcm.com">hello@probillrcm.com</a>.</p>
        </section>
        </div>
        </div>
              </>
  )
}

export default PrivacyPolicy;