import React from 'react'
import { footer_icon1, footer_icon2, footer_icon3, footer_icon4, footer_logo, logo } from '../../assets/images'
import PrivacyPolicy from '../pages/PrivacyPolicy';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <>
      <div className="footer p-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-12 col-md-6 p-2 mt-5">
              <div className="div p-0  d-none d-lg-block px-0 text-white">
                <img src={footer_logo} className='footer-logo     ' alt="" />
                <p className="footer-logo-para  text-start mt-4">ProbillRCM is a trusted partner for healthcare providers, offering specialized solutions to optimize the revenue cycle management process. By leveraging advanced tools and expertise, ProbillRCM ensures that medical professionals can focus on patient care while leaving the complexities of billing and revenue management to the experts. </p>
                <p className='text-muted footer-email text-start mt-4'>Email to : <span className="text-white fw-bold">hello@probillrcm.com</span> </p>
                <h5 className='text-start text-white mt-4 text-muted footer-email'>Contact Us :  <span className="text-white fw-bold">216-678-6887</span></h5>
                <p className='text-start footer-email mt-4 text-white'>30799 Pinetree Rd., #432, Pepper Pike, OH 44124 </p>
              </div>
              <img src={footer_logo} className='footer-logo d-lg-none ms-5 px-2' alt="" />

              <div className="div  flex-md-column  d-lg-none p-0 px-0 text-white">
                <div className="div">
                  <p className="footer-logo-para  text-start mt-4">ProbillRCM is a trusted partner for healthcare providers, offering specialized solutions to optimize the revenue cycle management process. By leveraging advanced tools and expertise, ProbillRCM ensures that medical professionals can focus on patient care while leaving the complexities of billing and revenue management to the experts. </p>
                </div>
                <div className="div mt-1 mt-md-0">
                  <p className='text-muted   m-0 text-md-start text-lg-end text-start  mt-4'>Email to : <span className="text-white fw-medium " style={{ fontSize: '14px' }}>hello@ProBillRCM.com</span> </p>
                  <h5 className=' text-white  text-muted text-lg-end text-start  text-md-start m-0 '> Contact us : <span className="text-white fw-medium " style={{ fontSize: '14px' }}>216-678-6887</span></h5>
                  <p className=' footer-email text-md-start text-lg-end text-start   text-white'>2972 Westheimer Rd. Santa <br /> Ana, Illinois 85486 </p>
                </div>
              </div>
            </div>
            <div className=" col-6 col-md-3 col-lg-2 mt-lg-5 p-2 d-flex justify-content-center">
              {/* <div className="div">
                <h5 className='text-start text-white footer-link-head'>Quick Links</h5>
                <p className="footer-email text-white text-start">Solutions</p>
                <p className="footer-email text-white text-start">Technology</p>
                <p className="footer-email text-white text-start">Webinars</p>
                <p className="footer-email text-white text-start">Insights</p>
                <p className="footer-email text-white text-start">Careers</p>
                <p className="footer-email text-white text-start">About Us</p>
                <p className="footer-email text-white text-start">Contact Sales</p>
              </div> */}
            </div>
            <div className="col-6 col-md-3 col-lg-2 mt-lg-5 p-2 d-flex justify-content-center">
              {/* <div className="div">
                <h5 className='text-start text-white footer-link-head'>Social Media</h5>
                <p className="footer-email text-white text-start">Facebook</p>
                <p className="footer-email text-white text-start">Instagram</p>
                <p className="footer-email text-white text-start">Youtube</p>
                <p className="footer-email text-white text-start">twiter</p>

              </div> */}
            </div>
            <div className="col-12 col-lg-4 mt-lg-5 p-2 d-flex justify-content-center">
              <div className="div">
                <h5 className='text-start text-white footer-link-head'>News Letter</h5>
                <p className="footer-email  text-start">Discover how ProbillRCM can streamline your medical billing, coding, and claims management. Our solutions are designed to save you time, reduce errors, and maximize revenue so you can focus on patient care. </p>
                <div className="d-flex mt-lg-3">
                  <input
                    type="email"
                    className="form-control input px-4"
                    placeholder="Enter your email ID"
                    aria-label="Email"
                  />
                  <button className="btn input-btn " type="button">
                    Submit
                  </button>
                </div>
                <h5 className='text-start d-none d-lg-block mt-lg-3 text-white'>Follow Us on:</h5>
                <div className="d-flex d-none d-lg-block  justify-content-start media-icon">
                  <img src={footer_icon2} className='footer-icon' alt="" />
                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon3} className='footer-icon' alt="" />
                  <img src={footer_icon4} className='footer-icon' alt="" />

                </div>
                <div className="d-flex d-lg-none mt-3  justify-content-center media-icon">
                  <h5 className='text-start mt-lg-5 text-white mt-2'>Follow Us on:</h5>

                  <img src={footer_icon2} className='footer-icon' alt="" />
                  <img src={footer_icon1} className='footer-icon' alt="" />
                  <img src={footer_icon3} className='footer-icon' alt="" />
                  <img src={footer_icon4} className='footer-icon' alt="" />

                </div>
                <div class="row mx-auto mt-4">
                  <div class="col-auto d-none d-md-block px-4 px-xl-1 me-4">
                    {/* <a class="text-decoration-none" href="/">
                      <p class="m-0  copy_link text-primary "> Terms & Conditions</p>
                    </a> */}
                    <Link to={"/terms-conditions"}className='footer-bottom-link '   style={{ color: 'rgba(255, 255, 255, 1)' ,textDecoration:"none!important"}}>Terms & Conditions</Link>
                    <hr className='mt-0'/>
                    </div>
                  <div class="col-auto d-none d-md-block px-4 px-xl-1 me-4">
                    {/* <a class="text-decoration-none" href="/privacy-policy">
                      <p class="m-0  copy_link ">Privacy Policy</p></a> */}
                      <Link to={"/privacy-policy"} className='footer-bottom-link' style={{ color: 'rgba(255, 255, 255, 1)' ,textDecoration:"none!important"}} >Privacy Policy</Link>
                      <hr className='mt-0'/>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="div d-flex align-items-center justify-content-center">
                <p className='footer-bottom-link' style={{ color: 'rgba(255, 255, 255, 1)' }}>© 2024 ProBillRCM | All Rights Reserved | Powered By <b className='text-underline'>RowthTech</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Footer