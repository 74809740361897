import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Navbar from "./componets/header/Navbar";
import Section from "./componets/pages/Section1";
import PrivacyPolicy from "./componets/pages/PrivacyPolicy";
import TermsConditions from "./componets/pages/TermsConditions";
import Footer from "./componets/footer/Footer";

function App() {
  return (
    <Router>  {/* Ensure Router wraps everything */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Section />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
