import React from 'react'

const TermsConditions = () => {
    window.scrollTo(0, 0);

    return (
        <>
            <div className="container" style={{marginTop:"7rem"}}>
            <div class="container mt-4">
        <h1>Terms of Use</h1>
        <p class="lead">Please read these Terms of Use and Privacy Policy carefully before using this site.</p>

        <section class="mt-4">
            <h2>Acceptance of Terms</h2>
            <p>By using the website, you accept and agree to be bound by these Terms of Use and Privacy Policy. This site and its related services are governed by the Terms of Use stated below, and your continued access to this site and its services is subject to your agreement to be bound by such Terms of Use and Privacy Policy, as they may change from time to time. If you do not agree to these Terms of Use, you may not use this site (the website or “Site”).</p>
        </section>

        <section class="mt-4">
            <h2>Legal Advice</h2>
            <p>Nothing on this site constitutes, or should be construed to constitute, any tax or legal advice or provide any warranties. The material is for informational purposes only with an understanding that local, state and federal laws and regulations may be different in various jurisdictions or may change over time. You should consult your own tax and legal professionals before engaging in any arrangements. We and our clients abide by all applicable privacy regulations, including but not limited to FTC Act and Health Insurance Portability and Accountability Act (HIPAA), but no HIPAA-protected information is accessible through this website.</p>
        </section>

        <section class="mt-4">
            <h2>Interaction and Surveys</h2>
            <p>From time-to-time our site may request feedback and/or information from you via survey. Participation in these requests or surveys is completely voluntary and you have a choice whether or not to disclose the requested information, which may include contact information (name and address) and demographic information. Feedback collected by these surveys will be used for purposes of monitoring or improving use of this site. It is a condition of your use of the website that all information that you provide must be correct, current, complete and not misleading.</p>
        </section>

        <section class="mt-4">
            <h2>Links</h2>
            <p>This website contains links to third party sites for your convenience. Please be aware that Probill and its affiliates are not responsible for any of the information, products, advertisements, and other content on these sites, nor do Probill or its affiliates make any representations as to such information, products, advertisements, and other content or its accuracy. Use of any linked third party site is solely at your own risk and we encourage you to read the terms and conditions of each linked third party site you choose to access, including the privacy statements of each such site.</p>
        </section>

        <section class="mt-4">
            <h2>No Warranties or Representations</h2>
            <p>Probill makes no warranty whatsoever, express or implied, including any warranty as to accuracy, completeness or timeliness, concerning the information contained on or linked through this site, and you should not assume that such information is accurate, complete or the most up-to-date information available. Probill shall not be liable for any loss, claim or damages caused in whole or in part by its provision of, or your use of, any of the information contained on, or linked through, this site. Probill disclaims any express statutory or implied warranties, including, without limitation, warranties of merchantability or fitness for a particular purpose.</p>
        </section>

        <section class="mt-4">
            <h2>Intellectual Property Rights</h2>
            <p>The website and its entire contents, features, and functionality (including but not limited to all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof), are owned by the Probill RCM, Inc., and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            <p>These Terms of Use permit you to use the website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our website, except as follows:</p>
            <ul>
                <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
                <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
                <li>You may print one copy of a reasonable number of pages of the website for your own personal, non-commercial use and not for further reproduction, publication, or distribution.</li>
            </ul>
            <p>You must not:</p>
            <ul>
                <li>Modify copies of any materials from this site.</li>
                <li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
                <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
            </ul>
        </section>

        <section class="mt-4">
            <h2>Copyrights</h2>
            <p>©2025 Probill RCM Inc. All rights reserved. The text, graphics, sound files, animation files, video files and their arrangements on all Probill sites are subject to copyright and other intellectual property protection. These materials may not be copied in whole or in part for commercial use or distribution, nor may these objects be modified or reposted to other sites.</p>
        </section>

        <section class="mt-4">
            <h2>Limitations on Liability</h2>
            <p>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS AFFILIATES, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.</p>
        </section>

        <section class="mt-4">
            <h2>Changes to Our Terms of Use and Privacy Policy</h2>
            <p>We may modify this Terms of Use at any time and will post any changes by a link from our homepage so that you always know what information we collect and how we use it. Such changes will be effective immediately upon posting and shall apply prospectively only. Please consult this Terms of Use regularly for any important changes as they occur.</p>
            <p>This Terms of Use is effective March 2025.</p>
        </section>
    </div>
            </div>
        </>
    )
}

export default TermsConditions;